import $ from 'jquery';

$(document).ready(function () {
  step2();
});

function step2() {
  let form = document.querySelector('#claims-handling-new-form');
  let existingForm = document.querySelector('#existing-claims-handling-form');
  let primaryCheckbox = $('#self-administered-checkbox');
  let secondaryCheckbox = $('#georgia-agent-checkbox');
  let s2Nextlink = $('.step-2-next').attr('href');
  let s2link = $('#step-2-submit').attr('href');

  //

  //comment submit
  var rejectFormButton = $('#step-2-reject-form');
  var commentForm = $('#comment-2-step-form');

  $('#step-2-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });

  $('.self-administered-checkbox .first-label').on('click', function () {
    let parent = $(this).parent('.contact-info-checkbox');
    let label = parent.find('label.label-hidden');
    label.toggleClass('confirm');
  });
  $('.self-administered-checkbox .second-label').on('click', function () {
    if ($('.self-administered-edit').length) {
      $('.self-administered-edit-wrap').show();
      $('.self-administered-info-wrap').hide();
    }
  });

  $('.georgia-agent-checkbox .second-label').on('click', function () {
    if ($('.georgia-agent-edit').length) {
      $('.georgia-agent-edit-wrap').show();
      $('.georgia-agent-info-wrap').hide();
    }
  });

  $('.additional-third-party-administrator-checkbox .second-label').on(
    'click',
    function () {
      $('.additional-third-party-administrator-info').hide();
      $('.additional-third-party-administrator-edit-wrap').show();
    }
  );

  $('.third-party-administrator-checkbox .second-label').on(
    'click',
    function () {
      $('.third-party-administrator-info-wrap').hide();
      $('.third-party-administrator-edit-wrap').show();
    }
  );

  $('.additional-admin-add').on('click', function () {
    $('.additional-third-party-administrator-edit').slideToggle();
    $('#third-party-administrator-files').toggleClass('have-additional');
    $('.additional-button').toggleClass('additional-button');
  });

  $('.second-additional-admin-add').on('click', function () {
    $('.second-additional-third-party-administrator-edit').slideToggle();
    $('#third-party-administrator-files').toggleClass('have-additional');
    $('.second-additional-button').toggleClass('second-additional-button');
  });

  let selfAdministeredWrap = $('.self-administered-edit');
  let thirdAdministeredWrap = $('.third-party-administrator-edit');
  let georgiaAgentAdminWrap = $('.georgia-agent-edit');
  let additionalthirdAdministered = $(
    '.additional-third-party-administrator-edit'
  );
  let thirdPartyAdministratorFiles = $('#third-party-administrator-files');

  $('.third-party-administered').on('click', function () {
    selfAdministeredWrap.hide();
    thirdAdministeredWrap.show();
    thirdPartyAdministratorFiles.show();
    $('.third-party-administrator-text').show();
  });
  $('.self-administered').on('click', function () {
    selfAdministeredWrap.show();
    thirdAdministeredWrap.hide();
    additionalthirdAdministered.hide();
    thirdPartyAdministratorFiles.hide();
    $('.third-party-administrator-text').hide();
  });

  $('.third_party_administrator_license-button').on('click', function () {
    $('input[name="third_party_administrator_license"]').click();
  });
  $('input[name="third_party_administrator_license"]').on(
    'change',
    function () {
      var fileName = $(this).val().split('\\').pop();
      $(this)
        .parents('.form-upload-button-wrap')
        .append("<span class='file_names'>" + fileName + '</span>');
    }
  );

  $('.additional-third_party_administrator_license-button').on(
    'click',
    function () {
      $('input[name="additional_third_party_administrator_license"]').click();
    }
  );
  $('input[name="additional_third_party_administrator_license"]').on(
    'change',
    function () {
      var fileName = $(this).val().split('\\').pop();
      $(this)
        .parents('.form-upload-button-wrap')
        .append("<span class='file_names'>" + fileName + '</span>');
    }
  );

  $('.second_additional_third_party_administrator_license-button').on(
    'click',
    function () {
      $(
        'input[name="second_additional_third_party_administrator_license"]'
      ).click();
    }
  );
  $('input[name="second_additional_third_party_administrator_license"]').on(
    'change',
    function () {
      var fileName = $(this).val().split('\\').pop();
      $(this)
        .parents('.form-upload-button-wrap')
        .append("<span class='file_names'>" + fileName + '</span>');
    }
  );

  $('.third_party_administrator_contract-button').on('click', function () {
    $('input[name="third_party_administrator_contract"]').click();
  });
  $('input[name="third_party_administrator_contract"]').on(
    'change',
    function () {
      var fileName = $(this).val().split('\\').pop();
      $(this)
        .parents('.form-upload-button-wrap')
        .append("<span class='file_names'>" + fileName + '</span>');
    }
  );

  $('.additional-third_party_administrator_contract-button').on(
    'click',
    function () {
      $('input[name="additional_third_party_administrator_contract"]').click();
    }
  );
  $('input[name="additional_third_party_administrator_contract"]').on(
    'change',
    function () {
      var fileName = $(this).val().split('\\').pop();
      $(this)
        .parents('.form-upload-button-wrap')
        .append("<span class='file_names'>" + fileName + '</span>');
    }
  );

  $('.second_additional_third_party_administrator_contract-button').on(
    'click',
    function () {
      $(
        'input[name="second_additional_third_party_administrator_contract"]'
      ).click();
    }
  );
  $('input[name="second_additional_third_party_administrator_contract"]').on(
    'change',
    function () {
      var fileName = $(this).val().split('\\').pop();
      $(this)
        .parents('.form-upload-button-wrap')
        .append("<span class='file_names'>" + fileName + '</span>');
    }
  );

  $(
    '.third_party_administrator_errors_and_omissions_insurance_policy-button'
  ).on('click', function () {
    $(
      'input[name="third_party_administrator_errors_and_omissions_insurance_policy"]'
    ).click();
  });
  $(
    'input[name="third_party_administrator_errors_and_omissions_insurance_policy"]'
  ).on('change', function () {
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.form-upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  $(
    '.additional-third_party_administrator_errors_and_omissions_insurance_policy-button'
  ).on('click', function () {
    $(
      'input[name="additional_third_party_administrator_errors_and_omissions_insurance_policy"]'
    ).click();
  });

  $(
    'input[name="additional_third_party_administrator_errors_and_omissions_insurance_policy"]'
  ).on('change', function () {
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.form-upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  $(
    '.second_additional_third_party_administrator_errors_and_omissions_insurance_policy-button'
  ).on('click', function () {
    $(
      'input[name="second_additional_third_party_administrator_errors_and_omissions_insurance_policy"]'
    ).click();
  });

  $(
    'input[name="second_additional_third_party_administrator_errors_and_omissions_insurance_policy"]'
  ).on('change', function () {
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.form-upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  $('.third_party_administrator_reserving_methodology-button').on(
    'click',
    function () {
      $(
        'input[name="third_party_administrator_reserving_methodology"]'
      ).click();
    }
  );

  $('input[name="third_party_administrator_reserving_methodology"]').on(
    'change',
    function () {
      var fileName = $(this).val().split('\\').pop();
      $(this)
        .parents('.form-upload-button-wrap')
        .append("<span class='file_names'>" + fileName + '</span>');
    }
  );

  $('.additional-third_party_administrator_reserving_methodology-button').on(
    'click',
    function () {
      $(
        'input[name="additional_third_party_administrator_reserving_methodology"]'
      ).click();
    }
  );

  $(
    'input[name="additional_third_party_administrator_reserving_methodology"]'
  ).on('change', function () {
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.form-upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  $(
    '.second_additional_third_party_administrator_reserving_methodology-button'
  ).on('click', function () {
    $(
      'input[name="second_additional_third_party_administrator_reserving_methodology"]'
    ).click();
  });

  $(
    'input[name="second_additional_third_party_administrator_reserving_methodology"]'
  ).on('change', function () {
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.form-upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  $('#step-2-submit').on('click', function (el) {
    el.preventDefault();

    if ($(form).length) {
      let formArray = [];

      function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
      }

      let data = objectifyForm(form);
      formArray.push(data);
      formArray = JSON.stringify(formArray);
      let file1 = $('#third_party_administrator_license')[0].files[0];
      let file2 = $(
        '#third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let file3 = $('#third_party_administrator_contract')[0].files[0];
      let file4 = $('#third_party_administrator_reserving_methodology')[0]
        .files[0];
      let fileA1 = $('#additional_third_party_administrator_license')[0]
        .files[0];
      let fileA2 = $(
        '#additional_third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let fileA3 = $('#additional_third_party_administrator_contract')[0]
        .files[0];
      let fileA4 = $(
        '#additional_third_party_administrator_reserving_methodology'
      )[0].files[0];
      let fileSA1 = $('#second_additional_third_party_administrator_license')[0]
        .files[0];
      let fileSA2 = $(
        '#second_additional_third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let fileSA3 = $(
        '#second_additional_third_party_administrator_contract'
      )[0].files[0];
      let fileSA4 = $(
        '#second_additional_third_party_administrator_reserving_methodology'
      )[0].files[0];
      let allFiles = [
        file1,
        file2,
        file3,
        file4,
        fileA1,
        fileA2,
        fileA3,
        fileA4,
        fileSA1,
        fileSA2,
        fileSA3,
        fileSA4,
      ];
      var formData = new FormData();
      console.log(fileA2);
      formData.append('action', 'claims_handling');
      formData.append('claims_handling_text', formArray);
      formData.append('claims_handling_file1', file1);
      formData.append('claims_handling_file2', file2);
      formData.append('claims_handling_file3', file3);
      formData.append('claims_handling_file4', file4);
      formData.append('additional_claims_handling_file1', fileA1);
      formData.append('additional_claims_handling_file2', fileA2);
      formData.append('additional_claims_handling_file3', fileA3);
      formData.append('additional_claims_handling_file4', fileA4);
      formData.append('second_additional_claims_handling_file1', fileSA1);
      formData.append('second_additional_claims_handling_file2', fileSA2);
      formData.append('second_additional_claims_handling_file3', fileSA3);
      formData.append('second_additional_claims_handling_file4', fileSA4);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          window.location.href = s2link;
        })
        .catch((err) => console.log(err));
    } else if ($(existingForm).length) {
      let formArray = [];

      function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
      }

      let data = objectifyForm(existingForm);
      formArray.push(data);
      formArray = JSON.stringify(formArray);
      let file1 = $('#third_party_administrator_license')[0].files[0];
      let file2 = $(
        '#third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let file3 = $('#third_party_administrator_contract')[0].files[0];
      let file4 = $('#third_party_administrator_reserving_methodology')[0]
        .files[0];
      let fileA1 = $('#additional_third_party_administrator_license')[0]
        .files[0];
      let fileA2 = $(
        '#additional_third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let fileA3 = $('#additional_third_party_administrator_contract')[0]
        .files[0];
      let fileA4 = $(
        '#additional_third_party_administrator_reserving_methodology'
      )[0].files[0];
      let fileSA1 = $('#second_additional_third_party_administrator_license')[0]
        .files[0];
      let fileSA2 = $(
        '#second_additional_third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let fileSA3 = $(
        '#second_additional_third_party_administrator_contract'
      )[0].files[0];
      let fileSA4 = $(
        '#second_additional_third_party_administrator_reserving_methodology'
      )[0].files[0];
      let allFiles = [
        file1,
        file2,
        file3,
        file4,
        fileA1,
        fileA2,
        fileA3,
        fileA4,
        fileSA1,
        fileSA2,
        fileSA3,
        fileSA4,
      ];
      var formData = new FormData();

      formData.append('action', 'claims_handling');
      formData.append('claims_handling_text', formArray);
      formData.append('claims_handling_file1', file1);
      formData.append('claims_handling_file2', file2);
      formData.append('claims_handling_file3', file3);
      formData.append('claims_handling_file4', file4);
      formData.append('additional_claims_handling_file1', fileA1);
      formData.append('additional_claims_handling_file2', fileA2);
      formData.append('additional_claims_handling_file3', fileA3);
      formData.append('additional_claims_handling_file4', fileA4);
      formData.append('second_additional_claims_handling_file1', fileSA1);
      formData.append('second_additional_claims_handling_file2', fileSA2);
      formData.append('second_additional_claims_handling_file3', fileSA3);
      formData.append('second_additional_claims_handling_file4', fileSA4);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          window.location.href = s2link;
        })
        .catch((err) => console.log(err));
    }
  });

  $('.step-2-next').on('click', function (el) {
    el.preventDefault();

    if ($(form).length) {
      let formArray = [];

      function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
      }

      let data = objectifyForm(form);
      formArray.push(data);
      formArray = JSON.stringify(formArray);
      let file1 = $('#third_party_administrator_license')[0].files[0];
      let file2 = $(
        '#third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let file3 = $('#third_party_administrator_contract')[0].files[0];
      let file4 = $('#third_party_administrator_reserving_methodology')[0]
        .files[0];
      let fileA1 = $('#additional_third_party_administrator_license')[0]
        .files[0];
      let fileA2 = $(
        '#additional_third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let fileA3 = $('#additional_third_party_administrator_contract')[0]
        .files[0];
      let fileA4 = $(
        '#additional_third_party_administrator_reserving_methodology'
      )[0].files[0];
      let fileSA1 = $('#second_additional_third_party_administrator_license')[0]
        .files[0];
      let fileSA2 = $(
        '#second_additional_third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let fileSA3 = $(
        '#second_additional_third_party_administrator_contract'
      )[0].files[0];
      let fileSA4 = $(
        '#second_additional_third_party_administrator_reserving_methodology'
      )[0].files[0];
      let allFiles = [
        file1,
        file2,
        file3,
        file4,
        fileA1,
        fileA2,
        fileA3,
        fileA4,
        fileSA1,
        fileSA2,
        fileSA3,
        fileSA4,
      ];
      var formData = new FormData();
      console.log(fileA2);
      formData.append('action', 'claims_handling');
      formData.append('claims_handling_text', formArray);
      formData.append('claims_handling_file1', file1);
      formData.append('claims_handling_file2', file2);
      formData.append('claims_handling_file3', file3);
      formData.append('claims_handling_file4', file4);
      formData.append('additional_claims_handling_file1', fileA1);
      formData.append('additional_claims_handling_file2', fileA2);
      formData.append('additional_claims_handling_file3', fileA3);
      formData.append('additional_claims_handling_file4', fileA4);
      formData.append('second_additional_claims_handling_file1', fileSA1);
      formData.append('second_additional_claims_handling_file2', fileSA2);
      formData.append('second_additional_claims_handling_file3', fileSA3);
      formData.append('second_additional_claims_handling_file4', fileSA4);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          window.location.href = s2Nextlink;
        })
        .catch((err) => console.log(err));
    } else if ($(existingForm).length) {
      let formArray = [];

      function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
      }

      let data = objectifyForm(existingForm);
      formArray.push(data);
      formArray = JSON.stringify(formArray);
      let file1 = $('#third_party_administrator_license')[0].files[0];
      let file2 = $(
        '#third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let file3 = $('#third_party_administrator_contract')[0].files[0];
      let file4 = $('#third_party_administrator_reserving_methodology')[0]
        .files[0];
      let fileA1 = $('#additional_third_party_administrator_license')[0]
        .files[0];
      let fileA2 = $(
        '#additional_third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let fileA3 = $('#additional_third_party_administrator_contract')[0]
        .files[0];
      let fileA4 = $(
        '#additional_third_party_administrator_reserving_methodology'
      )[0].files[0];
      let fileSA1 = $('#second_additional_third_party_administrator_license')[0]
        .files[0];
      let fileSA2 = $(
        '#second_additional_third_party_administrator_errors_and_omissions_insurance_policy'
      )[0].files[0];
      let fileSA3 = $(
        '#second_additional_third_party_administrator_contract'
      )[0].files[0];
      let fileSA4 = $(
        '#second_additional_third_party_administrator_reserving_methodology'
      )[0].files[0];
      let allFiles = [
        file1,
        file2,
        file3,
        file4,
        fileA1,
        fileA2,
        fileA3,
        fileA4,
        fileSA1,
        fileSA2,
        fileSA3,
        fileSA4,
      ];
      var formData = new FormData();

      formData.append('action', 'claims_handling');
      formData.append('claims_handling_text', formArray);
      formData.append('claims_handling_file1', file1);
      formData.append('claims_handling_file2', file2);
      formData.append('claims_handling_file3', file3);
      formData.append('claims_handling_file4', file4);
      formData.append('additional_claims_handling_file1', fileA1);
      formData.append('additional_claims_handling_file2', fileA2);
      formData.append('additional_claims_handling_file3', fileA3);
      formData.append('additional_claims_handling_file4', fileA4);
      formData.append('second_additional_claims_handling_file1', fileSA1);
      formData.append('second_additional_claims_handling_file2', fileSA2);
      formData.append('second_additional_claims_handling_file3', fileSA3);
      formData.append('second_additional_claims_handling_file4', fileSA4);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          window.location.href = s2Nextlink;
        })
        .catch((err) => console.log(err));
    }
  });

  // $('#third_party_administrator_state').on('change', function () {
  //   var inputValue = $(this).val();
  //   let section = $('.georgia-agent-edit');
  //   var $rowTemplate = $('.georgia-hidden .georgia-agent-edit').clone();
  //   let container = $('.georgia-agent-edit-section');
  //
  //   if (inputValue.toLowerCase() === 'georgia') {
  //     if (section.length) {
  //       section.remove();
  //       console.log(section);
  //     }
  //   } else {
  //     container.append($rowTemplate);
  //   }
  // });
  $(
    '#third_party_administrator_state, #additional_third_party_administrator_state, #second_additional_third_party_administrator_state'
  ).on('change', function () {
    let input1 = $('#third_party_administrator_state').val().trim();
    var input2 = $('#additional_third_party_administrator_state').val().trim();
    var input3 = $('#second_additional_third_party_administrator_state')
      .val()
      .trim();
    var errorBlock = $('#georgia-agent-edit-section');
    let state = $(this).val();

    if (state !== 'Georgia' && state !== 'GA' && state !== 'Georgia (GA)') {
      $(errorBlock).show();
    } else {
      $(errorBlock).hide();
    }
  });

  $('.info-button').on('click', function () {
    let parent = $(this).parent('.info-item');
    let item = parent.find('.info-button');
    item.addClass('info-show');
    let text = parent.next();
    text.slideDown();
  });
  $('.info-button').on('click', function () {
    let parent = $(this).parent('.georgia-agent-edit-section');
    let item = parent.find('.info-button');
    item.addClass('info-show');
    let text = parent.find('.georgia-agent-i-text');
    text.slideDown();
  });

  $('.i-text__close').on('click', function () {
    let parent = $(this).parent('.i-text');
    parent.slideUp();
  });
}
