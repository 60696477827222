import $ from 'jquery';

$(document).ready(function () {
  step5();
});

function step5() {
  let changeInOwnershipForm = $('#change-in-ownership-form');

  $('.step-5 .selection-buttons span').on('click', function () {
    $('.parent-company').show();
    $('.step-5 .info-text').hide();
    $('.step-5-next').show();
  });
  //comment submit
  var rejectFormButton = $('#step-5-reject-form');
  var commentForm = $('#comment-5-step-form');

  $('#professional_employer_intentions-yes').on('click', function () {
    $('.hidden-part').show();
  });

  $('#professional_employer_intentions-no').on('click', function () {
    $('.hidden-part').hide();
  });

  $('.change_in_ownership_select-wrap .first-label').on('click', () => {
    $('.change_in_ownership').show();
  });

  $('.change_in_ownership_select-wrap .second-label').on('click', () => {
    $('.change_in_ownership').hide();
  });

  $('.subsidiary_companies_select-wrap .first-label').on('click', () => {
    $('.subsidiary_companies_text').show();
  });

  $('.subsidiary_companies_select-wrap .second-label').on('click', () => {
    $('.subsidiary_companies_text').hide();
  });

  $('#step-5-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });

  $('#step-5-submit').on('click', function (el) {
    el.preventDefault();
    let s5Nextlink = $(this).attr('href');

    // changeInOwnershipForm.submit();
    // setTimeout(function () {
    //   window.location.href = s5Nextlink;
    // }, 300);

    var formDataArray = [];

    var formDatas = {};

    formDatas.change_in_ownership_select = $(changeInOwnershipForm)
      .find('input[name="change_in_ownership_select"]')
      .prop('checked');
    formDatas.change_in_ownership = $(changeInOwnershipForm)
      .find('textarea[name="change_in_ownership"]')
      .val();
    formDatas.subsidiary_companies_select = $(changeInOwnershipForm)
      .find('input[name="subsidiary_companies_select"]')
      .prop('checked');
    formDatas.subsidiary_companies_text = $(changeInOwnershipForm)
      .find('textarea[name="subsidiary_companies_text"]')
      .val();
    formDatas.professional_employer = $(changeInOwnershipForm)
      .find('input[name="professional_employer"]')
      .prop('checked');
    formDatas.professional_employer_intentions = $(changeInOwnershipForm)
      .find('input[name="professional_employer_intentions"]')
      .prop('checked');
    formDatas.becoming_date = $(changeInOwnershipForm)
      .find('input[name="becoming_date"]')
      .val();
    formDataArray.push(formDatas);

    formDataArray = JSON.stringify(formDataArray);
    console.log(formDataArray);
    var formData = new FormData();

    formData.append('action', 'change_in_ownership_step');
    formData.append('change_in_ownership_data', formDataArray);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    }) // wrapped
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s5Nextlink;
      })
      .catch((err) => console.log(err));
  });

  $('.step-5-next').on('click', function (el) {
    el.preventDefault();
    let s5Nextlink = $(this).attr('href');

    // changeInOwnershipForm.submit();
    // setTimeout(function () {
    //   window.location.href = s5Nextlink;
    // }, 300);

    var formDataArray = [];

    var formDatas = {};

    formDatas.change_in_ownership_select = $(changeInOwnershipForm)
      .find('input[name="change_in_ownership_select"]')
      .prop('checked');
    formDatas.change_in_ownership = $(changeInOwnershipForm)
      .find('textarea[name="change_in_ownership"]')
      .val();
    formDatas.subsidiary_companies_select = $(changeInOwnershipForm)
      .find('input[name="subsidiary_companies_select"]')
      .prop('checked');
    formDatas.subsidiary_companies_text = $(changeInOwnershipForm)
      .find('textarea[name="subsidiary_companies_text"]')
      .val();
    formDatas.professional_employer = $(changeInOwnershipForm)
      .find('input[name="professional_employer"]')
      .prop('checked');
    formDatas.professional_employer_intentions = $(changeInOwnershipForm)
      .find('input[name="professional_employer_intentions"]')
      .prop('checked');
    formDatas.becoming_date = $(changeInOwnershipForm)
      .find('input[name="becoming_date"]')
      .val();
    formDataArray.push(formDatas);

    formDataArray = JSON.stringify(formDataArray);
    console.log(formDataArray);
    var formData = new FormData();

    formData.append('action', 'change_in_ownership_step');
    formData.append('change_in_ownership_data', formDataArray);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    }) // wrapped
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s5Nextlink;
      })
      .catch((err) => console.log(err));
  });
}
