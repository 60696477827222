import $ from 'jquery';

$(document).ready(function () {
  step6();
});

function step6() {
  $('.info-button').on('click', function () {
    let parent = $(this).parent('.employee-data-form__item');
    let item = parent.find('.info-button');
    item.addClass('info-show');
    let text = parent.next();
    text.slideDown();
  });

  $('.i-text__close').on('click', function () {
    let parent = $(this).parent('.i-text');
    parent.slideUp();
  });
  let employeeDataForm = $('#employee-data-form');
  let step6Checkbox = $(
    '#did_the_member_company_have_any_employees_working_soley_and_exclusively'
  );

  $('.employee-data-form__item .first-label').on('click', () => {
    $('.how-many').css('display', 'flex');
  });

  $('.employee-data-form__item .second-label').on('click', () => {
    $('.how-many').css('display', 'none');
  });

  if ($('.employee-data-form__item .first-label').hasClass('active-label')) {
    $('.how-many').css('display', 'flex');
  }

  $('#step-6-submit').on('click', function (el) {
    el.preventDefault();
    let s6Nextlink = $(this).attr('href');
    if ($('.canceled-member').length) {
    } else {
      // employeeDataForm.submit();
      // setTimeout(function () {
      //   window.location.href = s6Nextlink;
      // }, 300);

      var formDataArray = [];

      var formDatas = {};

      formDatas.state_the_average_number_of_employees_in_georgia = $(
        employeeDataForm
      )
        .find('input[name="state_the_average_number_of_employees_in_georgia"]')
        .val();
      formDatas.projected_number_of_employees = $(employeeDataForm)
        .find('input[name="projected_number_of_employees"]')
        .val();
      formDatas.state_the_average_number_of_out_of_state_employees = $(
        employeeDataForm
      )
        .find(
          'input[name="state_the_average_number_of_out-of-state_employees"]'
        )
        .val();
      formDatas.number_of_out_of_state_employees = $(employeeDataForm)
        .find('input[name="number_of_out-of-state_employees"]')
        .val();
      formDatas.state_the_payroll = $(employeeDataForm)
        .find('input[name="state_the_payroll"]')
        .val();
      formDatas.estimated_payroll = $(employeeDataForm)
        .find('input[name="estimated_payroll"]')
        .val();
      formDatas.did_the_member_company_have_any_employees_working_soley_and_exclusively =
        $(employeeDataForm)
          .find(
            'input[name="did_the_member_company_have_any_employees_working_soley_and_exclusively"]'
          )
          .prop('checked');
      formDatas.how_many = $(employeeDataForm)
        .find('input[name="how_many"]')
        .val();

      formDataArray.push(formDatas);

      formDataArray = JSON.stringify(formDataArray);

      var formData = new FormData();
      console.log(formDataArray);
      formData.append('action', 'employee_data');
      formData.append('employee_data_data', formDataArray);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      }) // wrapped
        .then((res) => res.text())
        .then((data) => {
          window.location.href = s6Nextlink;
        })
        .catch((err) => console.log(err));
    }
  });

  $('.step-6-next').on('click', function (el) {
    el.preventDefault();
    let s6Nextlink = $(this).attr('href');
    if ($('.canceled-member').length) {
    } else {
      // employeeDataForm.submit();
      // setTimeout(function () {
      //   window.location.href = s6Nextlink;
      // }, 300);

      var formDataArray = [];

      var formDatas = {};

      formDatas.state_the_average_number_of_employees_in_georgia = $(
        employeeDataForm
      )
        .find('input[name="state_the_average_number_of_employees_in_georgia"]')
        .val();
      formDatas.projected_number_of_employees = $(employeeDataForm)
        .find('input[name="projected_number_of_employees"]')
        .val();
      formDatas.state_the_average_number_of_out_of_state_employees = $(
        employeeDataForm
      )
        .find(
          'input[name="state_the_average_number_of_out-of-state_employees"]'
        )
        .val();
      formDatas.number_of_out_of_state_employees = $(employeeDataForm)
        .find('input[name="number_of_out-of-state_employees"]')
        .val();
      formDatas.state_the_payroll = $(employeeDataForm)
        .find('input[name="state_the_payroll"]')
        .val();
      formDatas.estimated_payroll = $(employeeDataForm)
        .find('input[name="estimated_payroll"]')
        .val();
      formDatas.did_the_member_company_have_any_employees_working_soley_and_exclusively =
        $(employeeDataForm)
          .find(
            'input[name="did_the_member_company_have_any_employees_working_soley_and_exclusively"]'
          )
          .prop('checked');
      formDatas.how_many = $(employeeDataForm)
        .find('input[name="how_many"]')
        .val();

      formDataArray.push(formDatas);

      formDataArray = JSON.stringify(formDataArray);

      var formData = new FormData();
      console.log(formDataArray);
      formData.append('action', 'employee_data');
      formData.append('employee_data_data', formDataArray);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      }) // wrapped
        .then((res) => res.text())
        .then((data) => {
          window.location.href = s6Nextlink;
        })
        .catch((err) => console.log(err));
    }
  });

  //comment submit
  var rejectFormButton = $('#step-6-reject-form');
  var commentForm = $('#comment-6-step-form');

  $('#step-6-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });
}
