import $ from 'jquery';

$(document).ready(function () {
  step10();
});

function step10() {
  $('.no-parent').on('click', function () {
    $('.no-captive-nformation').submit();
  });
  let captiveInformationForm = $('#captive-information-form');
  let s10NextLink = $('.step-10-next').attr('href');
  $('#step-10-submit').on('click', function (el) {
    el.preventDefault();
    let s10link = $(this).attr('href');

    let form = document.querySelector('#captive-information-form');
    let formArray = [];
    function objectifyForm(formArray) {
      //serialize data function
      var returnArray = {};
      for (var i = 0; i < formArray.length; i++) {
        returnArray[formArray[i]['name']] = formArray[i]['value'];
      }
      return returnArray;
    }

    let data = objectifyForm(form);
    formArray.push(data);
    formArray = JSON.stringify(formArray);
    let file1 = $('#most_recent_captive')[0].files[0];
    var formData = new FormData();

    formData.append('action', 'captive_information');
    formData.append('captive_information_text', formArray);
    formData.append('captive_information_file1', file1);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s10link;
      })
      .catch((err) => console.log(err));
  });

  $('input[name="most_recent_captive"]').on('change', function () {
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.form-upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  $('.step-10 .selection-buttons .selection-buttons__item-yes').on(
    'click',
    function () {
      captiveInformationForm.slideDown();
    }
  );

  $('.step-10 .selection-buttons .selection-buttons__item-no').on(
    'click',
    function () {
      captiveInformationForm.slideUp();
    }
  );

  $('.most_recent_captive-button').on('click', function () {
    $('input[name="most_recent_captive"]').click();
  });

  $('.step-10-next').on('click', function (el) {
    el.preventDefault();

    let form = document.querySelector('#captive-information-form');
    let formArray = [];
    function objectifyForm(formArray) {
      //serialize data function
      var returnArray = {};
      for (var i = 0; i < formArray.length; i++) {
        returnArray[formArray[i]['name']] = formArray[i]['value'];
      }
      return returnArray;
    }

    let data = objectifyForm(form);
    formArray.push(data);
    formArray = JSON.stringify(formArray);
    let file1 = $('#most_recent_captive')[0].files[0];
    var formData = new FormData();

    formData.append('action', 'captive_information');
    formData.append('captive_information_text', formArray);
    formData.append('captive_information_file1', file1);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s10NextLink;
      })
      .catch((err) => console.log(err));
  });

  //comment submit
  var rejectFormButton = $('#step-10-reject-form');
  var commentForm = $('#comment-10-step-form');

  $('#step-10-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });
}
