import $ from 'jquery';

$(document).ready(function () {
  step3();
});

function step3() {
  $('.no-parent').on('click', function () {
    $('.no-parent-company').submit();
  });
  let parentCompanyEditForm = $('#parent-company-edit-form');
  let parentCompanyCheckbox = $('#parent-company-checkbox');

  $('.step-3 .selection-buttons span').on('click', function () {
    $('.parent-company').show();
    $('.step-3 .info-text').hide();
    $('.step-3-next').show();
  });

  $('.parent-company-checkbox .second-label').on('click', function () {
    $('.parent-company').show();
    $('.step-3-next').show();
    $('.step-3 .info-text').hide();
    $('.parent-company-old').hide();
  });
  $('.parent-company-checkbox .first-label').on('click', function () {
    $('.step-3-next').show();
  });

  var rejectFormButton = $('#step-3-reject-form');
  var commentForm = $('#comment-3-step-form');

  $('#step-3-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });
  $('#step-3-submit').on('click', function (el) {
    el.preventDefault();
    let link = $(this).attr('href');
    if ($('.new-member').length) {
      let form = document.querySelector('#parent-company-edit-form');
      let formArray = [];
      function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
      }

      let data = objectifyForm(form);
      formArray.push(data);
      formArray = JSON.stringify(formArray);
      var formData = new FormData();

      formData.append('action', 'parent_company');
      formData.append('parent_company_text', formArray);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          window.location.href = link;
        })
        .catch((err) => console.log(err));
    } else {
      // Checkbox validation
      if (parentCompanyCheckbox.prop('checked')) {
        let form = document.querySelector('#parent-company-edit-form');
        let formArray = [];
        function objectifyForm(formArray) {
          //serialize data function
          var returnArray = {};
          for (var i = 0; i < formArray.length; i++) {
            returnArray[formArray[i]['name']] = formArray[i]['value'];
          }
          return returnArray;
        }

        let data = objectifyForm(form);
        formArray.push(data);
        formArray = JSON.stringify(formArray);
        var formData = new FormData();

        formData.append('action', 'parent_company');
        formData.append('parent_company_text', formArray);
        fetch(ajax_object.ajax_url, {
          method: 'POST',
          body: formData,
        })
          .then((res) => res.text())
          .then((data) => {
            window.location.href = link;
          })
          .catch((err) => console.log(err));
      } else {
        parentCompanyCheckbox
          .next('.label-hidden')
          .css('border', '#ff0766 solid 2px');
      }
    }
  });

  $('.step-3-next').on('click', function (el) {
    el.preventDefault();
    let link = $(this).attr('href');
    if ($('.new-member').length) {
      let form = document.querySelector('#parent-company-edit-form');
      let formArray = [];
      function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
      }

      let data = objectifyForm(form);
      formArray.push(data);
      formArray = JSON.stringify(formArray);
      var formData = new FormData();

      formData.append('action', 'parent_company');
      formData.append('parent_company_text', formArray);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          window.location.href = link;
        })
        .catch((err) => console.log(err));
    } else {
      // Checkbox validation
      if (parentCompanyCheckbox.prop('checked')) {
        let form = document.querySelector('#parent-company-edit-form');
        let formArray = [];
        function objectifyForm(formArray) {
          //serialize data function
          var returnArray = {};
          for (var i = 0; i < formArray.length; i++) {
            returnArray[formArray[i]['name']] = formArray[i]['value'];
          }
          return returnArray;
        }

        let data = objectifyForm(form);
        formArray.push(data);
        formArray = JSON.stringify(formArray);
        var formData = new FormData();

        formData.append('action', 'parent_company');
        formData.append('parent_company_text', formArray);
        fetch(ajax_object.ajax_url, {
          method: 'POST',
          body: formData,
        })
          .then((res) => res.text())
          .then((data) => {
            window.location.href = link;
          })
          .catch((err) => console.log(err));
      } else {
        parentCompanyCheckbox
          .next('.label-hidden')
          .css('border', '#ff0766 solid 2px');
      }
    }
  });
}
