import $ from 'jquery';

$(document).ready(function () {
  step9();
});

function step9() {
  $('.no-parent').on('click', function () {
    $('.no-cat-claims').submit();
  });
  $('.step-9 .selection-buttons .item-yes').on('click', function () {
    $('.instruction').show();
    $('.cat-claims-edit').show();
    $('.step-9 .question').hide();
    $('.step-9 .selection-buttons').hide();
  });
  let catClaimsForm = $('#cat-claims-form');
  $('#step-9-submit').on('click', function (el) {
    el.preventDefault();
    let s9link = $(this).attr('href');
    if (catClaimsForm.length) {
      catClaimsForm.submit();
      setTimeout(function () {
        window.location.href = s9link;
      }, 200);
    }
  });

  //comment submit
  var rejectFormButton = $('#step-9-reject-form');
  var commentForm = $('#comment-9-step-form');

  $('#step-9-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });

  var $rowTemplate = $('#row-template .cat-claims-form__item-wrap').clone();

  $('.step-9 .add-row').on('click', function () {
    var $newRow = $rowTemplate.clone().removeAttr('id');
    catClaimsForm.append($newRow);
  });

  catClaimsForm.on('submit', function (e) {
    e.preventDefault();
    var formDatas = []; // Initialize an empty object
    var existingFormDatas = []; // Initialize an empty object
    var previousExistingTextFields = []; // Initialize an empty object

    let container = document.getElementById('cat-claims-form');
    var textFields = container.querySelectorAll('.new-row');
    var existingTextFields = container.querySelectorAll('.existing-row');

    textFields.forEach(function (item) {
      let formItem = {};
      let fields = item.querySelectorAll('input[type="text"]');
      fields.forEach(function (field) {
        formItem[field.name] = field.value;
      });
      formDatas.push(formItem);
    });

    formDatas = JSON.stringify(formDatas);

    $('#cat-claims-form .existing-row').each(function () {
      var existingFormData = {};

      existingFormData.claims_row_count = $(this)
        .find('input[name="claims_row_count"]')
        .val();
      existingFormData.claimant_name = $(this)
        .find('input[name="claimant_name"]')
        .val();
      existingFormData.total_medicalindemnity_reserves = $(this)
        .find('input[name="total_medicalindemnity_reserves"]')
        .val();

      existingFormDatas.push(existingFormData);
    });

    existingFormDatas = JSON.stringify(existingFormDatas);

    $('#cat-claims-form .previous-existing-row').each(function () {
      var previousExistingFormData = {};

      previousExistingFormData.claims_row_count = $(this)
        .find('input[name="previous_claims_row_count"]')
        .val();
      previousExistingFormData.claimant_name = $(this)
        .find('input[name="claimant_name"]')
        .val();
      previousExistingFormData.total_medicalindemnity_reserves = $(this)
        .find('input[name="total_medicalindemnity_reserves"]')
        .val();

      previousExistingTextFields.push(previousExistingFormData);
    });

    previousExistingTextFields = JSON.stringify(previousExistingTextFields);
    console.log(previousExistingTextFields);

    var formData = new FormData();
    formData.append('action', 'add_to_repeater');
    formData.append('repeaterData', formDatas);
    formData.append('existingrepeaterData', existingFormDatas);
    formData.append('previousexistingrepeaterData', previousExistingTextFields);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    }) // wrapped
      .then((res) => res.text())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  });

  $('.step-9 #delete-selected').on('click', function () {
    let parent = $(this).parent('.cat-claims-form__item');
    let row = parent.find('.row-count').val();

    row = JSON.stringify(row);

    var formData = new FormData();
    formData.append('action', 'delete_repeater_row');
    formData.append('delData', row);

    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    }) // wrapped
      .then((res) => res.text())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    parent.remove();
  });
  $(document).on('click', '.step-9 .del-new-button', function () {
    let parent = $(this).parent('.cat-claims-form__item');
    parent.remove();
  });

  $('.step-9 #add-row').hover(
    function () {
      $(this).text('ADD NEW');
    },
    function () {
      $(this).text('ADD NEW CAT CLAIM');
    }
  );

  $('.step-9-next').on('click', function (el) {
    el.preventDefault();

    catClaimsForm.submit();
    let s9Nextlink = $(this).attr('href');

    setTimeout(function () {
      window.location.href = s9Nextlink;
    }, 300);
  });
}
