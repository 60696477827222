import $ from 'jquery';

$(document).ready(function () {
  step14();
});

function step14() {
  let whoCompletedForm = $('#who-completed-form');
  $('#step-14-submit').on('click', function (el) {
    el.preventDefault();
    let s14link = $(this).attr('href');

    let formArray = [];
    function objectifyForm(formArray) {
      //serialize data function
      var returnArray = {};
      for (var i = 0; i < formArray.length; i++) {
        returnArray[formArray[i]['name']] = formArray[i]['value'];
      }
      return returnArray;
    }

    let form = document.querySelector('#who-completed-form');
    let data = objectifyForm(form);
    formArray.push(data);
    formArray = JSON.stringify(formArray);
    console.log(formArray);
    var formData = new FormData();

    formData.append('action', 'who_completed');
    formData.append('who_completed_data', formArray);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s14link;
      })
      .catch((err) => console.log(err));
  });

  $('.selection-buttons span').on('click', function () {
    $('.directors-and-officers-info').hide();
    $('.directors-and-officers-form-wrap').show();
  });

  $('.step-14-next').on('click', function (el) {
    el.preventDefault();
    let s14link = $(this).attr('href');

    let formArray = [];
    function objectifyForm(formArray) {
      //serialize data function
      var returnArray = {};
      for (var i = 0; i < formArray.length; i++) {
        returnArray[formArray[i]['name']] = formArray[i]['value'];
      }
      return returnArray;
    }

    let form = document.querySelector('#who-completed-form');
    let data = objectifyForm(form);
    formArray.push(data);
    formArray = JSON.stringify(formArray);
    console.log(formArray);
    var formData = new FormData();

    formData.append('action', 'who_completed');
    formData.append('who_completed_data', formArray);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s14link;
      })
      .catch((err) => console.log(err));
  });

  //comment submit
  var rejectFormButton = $('#step-14-reject-form');
  var commentForm = $('#comment-14-step-form');

  $('#step-14-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });
}
