import $ from 'jquery';

$(document).ready(function () {
  step13();
});

function step13() {
  let memberCertificationForm = $('#member-certification-form');
  $('#step-13-submit').on('click', function (el) {
    el.preventDefault();
    let s13linkNext = $(this).attr('href');

    if (
      $('#member-certification-form [required]').filter(function () {
        return $(this).val() === '';
      }).length > 0
    ) {
      $('#member-certification-form [required]').css(
        'border',
        '#ff0766 solid 2px'
      );
    } else {
      var formDataArray = [];

      var formDatas = {};

      formDatas.not_applicable = $(memberCertificationForm)
        .find('input[name="not_applicable"]')
        .prop('checked');
      formDatas.o_p_name = $(memberCertificationForm)
        .find('input[name="o_p_name"]')
        .val();
      formDatas.o_p_email = $(memberCertificationForm)
        .find('input[name="o_p_email"]')
        .val();
      formDatas.m_c_name = $(memberCertificationForm)
        .find('input[name="m_c_name"]')
        .val();
      formDatas.m_c_email = $(memberCertificationForm)
        .find('input[name="m_c_email"]')
        .val();

      formDataArray.push(formDatas);

      formDataArray = JSON.stringify(formDataArray);

      console.log(formDataArray);

      var formData = new FormData();
      console.log(formData);
      formData.append('action', 'member_certification');
      formData.append('member_certification_data', formDataArray);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      }) // wrapped
        .then((res) => res.text())
        .then((data) => {
          window.location.href = s13linkNext;
        })
        .catch((err) => console.log(err));
    }
  });

  $('.financial_information-button').on('click', function () {
    $('input[name="financial_information"]').click();
  });

  $('.step-13-next').on('click', function (el) {
    el.preventDefault();
    let s13linkNext = $(this).attr('href');

    if (
      $('#member-certification-form [required]').filter(function () {
        return $(this).val() === '';
      }).length > 0
    ) {
      $('#member-certification-form [required]').css(
        'border',
        '#ff0766 solid 2px'
      );
    } else {
      var formDataArray = [];

      var formDatas = {};

      formDatas.not_applicable = $(memberCertificationForm)
        .find('input[name="not_applicable"]')
        .prop('checked');
      formDatas.o_p_name = $(memberCertificationForm)
        .find('input[name="o_p_name"]')
        .val();
      formDatas.o_p_email = $(memberCertificationForm)
        .find('input[name="o_p_email"]')
        .val();
      formDatas.m_c_name = $(memberCertificationForm)
        .find('input[name="m_c_name"]')
        .val();
      formDatas.m_c_email = $(memberCertificationForm)
        .find('input[name="m_c_email"]')
        .val();

      formDataArray.push(formDatas);

      formDataArray = JSON.stringify(formDataArray);

      console.log(formDataArray);

      var formData = new FormData();
      console.log(formData);
      formData.append('action', 'member_certification');
      formData.append('member_certification_data', formDataArray);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      }) // wrapped
        .then((res) => res.text())
        .then((data) => {
          window.location.href = s13linkNext;
        })
        .catch((err) => console.log(err));
    }
  });

  //comment submit
  var rejectFormButton = $('#step-13-reject-form');
  var commentForm = $('#comment-13-step-form');

  $('#step-13-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });
}
