import $ from 'jquery';

$(document).ready(function () {
  step1();
});

function step1() {
  let contactInfoConfirmform = $('#contact-info-confirm');
  let newUsermform = $('#add-new-user-form');
  let step1Editform = $('#step-1-edit-form');
  let primaryCheckbox = $('#primary-contact-checkbox');
  let secondaryCheckbox = $('#secondary-contact-checkbox');

  //comment submit
  var rejectFormButton = $('#step-1-reject-form');
  var commentForm = $('#comment-1-step-form');

  $('#step-1-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });

  //Custom Checkbox
  $('.first-label').on('click', function () {
    let parent = $(this).parent('.contact-info-checkbox');
    let label = parent.find('label.label-hidden');
    label.toggleClass('confirm');
  });
  $('.second-label').on('click', function () {
    let parent = $(this).parent('.contact-info-checkbox');
    let label = parent.find('label.label-hidden');
    label.toggleClass('edit');
  });

  $('.step-1-next, #step-1-submit').on('click', function (el) {
    el.preventDefault();
    let link = $(this).attr('href');
    if ($('.new-member').length) {
      // newUsermform.submit();
      // setTimeout(function () {
      //   window.location.href = link;
      // }, 400);
      let form = document.querySelector('#add-new-user-form');
      let formArray = [];
      function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
      }

      let data = objectifyForm(form);
      formArray.push(data);
      formArray = JSON.stringify(formArray);
      console.log(formArray);
      var formData = new FormData();

      formData.append('action', 'contact_info');
      formData.append('contact_info_text', formArray);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          window.location.href = link;
        })
        .catch((err) => console.log(err));
    } else {
      // Checkbox validation
      if (
        primaryCheckbox.prop('checked') &&
        secondaryCheckbox.prop('checked')
      ) {
        contactInfoConfirmform.submit();
        setTimeout(function () {
          window.location.href = link;
        }, 300);
      } else {
        primaryCheckbox
          .next('.label-hidden')
          .css('border', '#ff0766 solid 2px');
        secondaryCheckbox
          .next('.label-hidden')
          .css('border', '#ff0766 solid 2px');
      }
    }
  });

  //Contact Info Page
  $('#contact-info-submit').on('click', function () {
    form.submit();
  });
}
